import React from 'react';

interface Props {
   className: string;
}

export const Name: React.FC<Props> = ({ className }) => {
   return (
      <svg className={className} viewBox="0 0 702 69" xmlns="http://www.w3.org/2000/svg">
         {[
            [
               'M25.7188 2C30.5938 2 34.8438 2.78125 38.4688 4.34375C42.0938 5.84375 44.9062 7.9375 46.9062 10.625C48.9688 13.3125 50 16.4062 50 19.9062C50 23.0312 49.1562 25.8125 47.4688 28.25C45.8438 30.6875 43.7812 32.5625 41.2812 33.875C43.1562 34.375 44.9688 35.2188 46.7188 36.4062C48.4688 37.5312 49.9062 39.0625 51.0312 41C52.1562 42.875 52.7188 45.2812 52.7188 48.2188C52.7188 51.7188 51.6562 54.9375 49.5312 57.875C47.4062 60.75 44.4375 63.0625 40.625 64.8125C36.8125 66.5625 32.375 67.4375 27.3125 67.4375C24.5625 67.4375 21.5938 67.3125 18.4062 67.0625C15.2188 66.875 12.1875 66.5938 9.3125 66.2188C6.4375 65.7812 4 65.3438 2 64.9062V4.34375C5.5 3.59375 9.28125 3.03125 13.3438 2.65625C17.4062 2.21875 21.5312 2 25.7188 2ZM25.7188 13.9062C21.7188 13.9062 18.1562 14.2188 15.0312 14.8438V30.0312H26.75C29.9375 30.0312 32.4375 29.25 34.25 27.6875C36.125 26.125 37.0625 24.125 37.0625 21.6875C37.0625 19.1875 35.9375 17.2812 33.6875 15.9688C31.5 14.5938 28.8438 13.9062 25.7188 13.9062ZM15.0312 54.5938C16.7812 55.0312 18.7812 55.3438 21.0312 55.5312C23.3438 55.6562 25.1875 55.7188 26.5625 55.7188C30.1875 55.7188 33.1562 55 35.4688 53.5625C37.8438 52.125 39.0312 50.0938 39.0312 47.4688C39.0312 44.8438 37.8125 42.9062 35.375 41.6562C32.9375 40.3438 30 39.6875 26.5625 39.6875H15.0312V54.5938Z',
               '[stroke-dasharray:355] [stroke-dashoffset:355]',
            ],
            [
               'M109.857 29V39.5938H82.1075V54.7812H113.514V66.3125H69.0762V3.5H113.514V14.75H82.1075V29H109.857Z',
               '[stroke-dasharray:333] [stroke-dashoffset:333] [animation-delay:200ms]',
            ],
            [
               'M187.246 3.5V66.7812H182.559L145.34 28.4375V66.3125H132.309V3.03125H136.996L174.215 41.9375V3.5H187.246Z',
               '[stroke-dasharray:346] [stroke-dashoffset:346] [animation-delay:400ms]',
            ],
            [
               'M250.992 3.5V54.5938H282.399V66.3125H237.961V3.5H250.992Z', 
               '[stroke-dasharray:215] [stroke-dashoffset:215] [animation-delay:600ms]'],
            [
               'M326.881 3.03125L356.131 66.3125H340.85L336.069 54.5938H311.225L306.444 66.3125H291.163L320.413 3.03125H326.881ZM323.694 24.3125L315.819 43.5312H331.569L323.694 24.3125Z',
               '[stroke-dasharray:283] [stroke-dashoffset:283] [animation-delay:800ms]',
            ],
            [
               'M430.614 3.03125V66.3125H417.582V28.0625L401.457 45.6875H397.707L381.395 28.0625V66.3125H368.364V3.03125H372.77L399.582 30.9688L426.207 3.03125H430.614Z',
               '[stroke-dasharray:367] [stroke-dashoffset:367] [animation-delay:1000ms]',
            ],
            [
               'M513.534 3.03125V66.3125H500.503V28.0625L484.378 45.6875H480.628L464.315 28.0625V66.3125H451.284V3.03125H455.69L482.503 30.9688L509.128 3.03125H513.534Z',
               '[stroke-dasharray:367] [stroke-dashoffset:367] [animation-delay:1200ms]',
            ],
            [
               'M574.985 29V39.5938H547.235V54.7812H578.641V66.3125H534.204V3.5H578.641V14.75H547.235V29H574.985Z',
               '[stroke-dasharray:333] [stroke-dashoffset:333] [animation-delay:1400ms]',
            ],
            [
               'M622.936 3.5C625.874 3.5 628.749 3.90625 631.561 4.71875C634.374 5.53125 636.905 6.78125 639.155 8.46875C641.468 10.1562 643.28 12.2812 644.593 14.8438C645.968 17.3438 646.655 20.3438 646.655 23.8438C646.655 27.5312 645.718 30.7188 643.843 33.4062C642.03 36.0312 639.624 38.0938 636.624 39.5938C633.624 41.0312 630.374 41.875 626.874 42.125L649.468 66.3125H632.311L610.468 40.625V66.3125H597.436V3.5H622.936ZM610.468 15.6875V31.5312C611.968 32.0312 613.624 32.4375 615.436 32.75C617.311 33 619.186 33.125 621.061 33.125C624.811 33.125 627.78 32.375 629.968 30.875C632.218 29.3125 633.343 27.125 633.343 24.3125C633.343 21.25 632.155 19.0625 629.78 17.75C627.468 16.375 624.624 15.6875 621.249 15.6875H610.468Z',
               '[stroke-dasharray:347] [stroke-dashoffset:347] [animation-delay:1600ms]',
            ],
            [
               'M679.138 2.28125C682.763 2.28125 686.075 2.78125 689.075 3.78125C692.075 4.78125 694.606 5.9375 696.669 7.25V20.0938C694.419 18.7188 692.013 17.4375 689.45 16.25C686.888 15.0625 683.981 14.4688 680.731 14.4688C679.106 14.4688 677.481 14.7188 675.856 15.2188C674.294 15.7188 672.981 16.4688 671.919 17.4688C670.919 18.4688 670.419 19.75 670.419 21.3125C670.419 23.375 671.325 24.9375 673.138 26C674.95 27.0625 677.169 28.125 679.794 29.1875L687.481 32.375C691.856 34.25 695.075 36.5312 697.138 39.2188C699.2 41.9062 700.231 44.9688 700.231 48.4062C700.231 52.1562 699.138 55.4688 696.95 58.3438C694.825 61.1562 691.95 63.375 688.325 65C684.763 66.625 680.794 67.4375 676.419 67.4375C672.731 67.4375 669.2 66.8125 665.825 65.5625C662.45 64.3125 659.513 62.7812 657.013 60.9688V48.125C659.763 50.125 662.794 51.8125 666.106 53.1875C669.419 54.5625 672.95 55.25 676.7 55.25C679.325 55.25 681.575 54.75 683.45 53.75C685.325 52.6875 686.263 51.125 686.263 49.0625C686.263 47.4375 685.481 46.125 683.919 45.125C682.419 44.0625 680.763 43.1875 678.95 42.5L669.294 38.75C664.419 36.875 661.106 34.4375 659.356 31.4375C657.669 28.375 656.825 25 656.825 21.3125C656.825 17.5 657.856 14.1875 659.919 11.375C662.044 8.5 664.794 6.28125 668.169 4.71875C671.544 3.09375 675.2 2.28125 679.138 2.28125Z',
               '[stroke-dasharray:283] [stroke-dashoffset:283] [animation-delay:1800ms]',
            ],
         ].map(([d, animateStyles], index) => (
            <path
               className={`${animateStyles} [transition:stroke_300ms] stroke-[3] animate-line  stroke-dark-gray dark:stroke-gray-100`}
               d={d as string}
               key={index}
            />
         ))}
      </svg>
   );
};
